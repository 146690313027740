import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss']
})
export class WarningBannerComponent implements OnInit {
  public show = false;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.warningBanner.subscribe(res => {
      this.show = res;
    });
  }

  public hideBanner(): void {
    this.show = false;
  }

  public showBanner(): void {
    this.show = true;
  }

}
