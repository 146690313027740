<div class="container">
  <div class="row justify-content-center">
    <div class="col-6 col-md-4 center">
      <img class="logo mt-5" src="../assets/icons/itv-logo.png" alt="ITV" />
      <br/>
      <p class="mat-body mt-5 text-left">
        Welcome to Embark. To login please press the button below.
      </p>
      <button (click)="login()" class="mt-5" mat-flat-button type="button" color="primary">Login</button>
    </div>
  </div>
</div>
