import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { LogoutDialogComponent } from './components/dialogs/logout-dialog/logout-dialog.component';
import { SharedModule } from './modules/shared/shared.module';
import { LoginComponent } from './components/login/login.component';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { HeaderComponent } from './components/header/header.component';
import { CorrelatorInterceptor } from './interceptors/correlatorInterceptor.interceptor';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { IdentityMetaAuthInterceptor } from './interceptors/identityAuth.interceptor';
import { GlobalErrorHandler } from './utilities/error/global-error-hander';
import { ErrorLoggerInterceptor } from './interceptors/error-logger.interceptor';
import { ErrorParentComponent } from './components/error-parent/error-parent.component';
import { EmailAliasPromptComponent } from './modules/requests/edit-user/modals/email-alias-prompt/email-alias-prompt.component';
import { VersionCheckService } from "./services/version-check.service";
import { PreInitService, preInitServiceFactory } from "./config/pre-init.service";
import { OktaConfigProvider, onAuthRequired } from "./config/okta-config.provider";
import OktaAuth from '@okta/okta-auth-js';

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CorrelatorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: IdentityMetaAuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorLoggerInterceptor, multi: true }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutDialogComponent,
    HeaderComponent,
    ErrorParentComponent,
    EmailAliasPromptComponent
  ],
  imports: [
    AppMaterialModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    OktaAuthModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
  ],
  providers: [
    PreInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: preInitServiceFactory,
      deps: [PreInitService, Injector],
      multi: true,
    },
    OktaConfigProvider,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    interceptorProviders,
    VersionCheckService,
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: { disabled: true }
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false}
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' }
    },
    {
       provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {strict: true}
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
