import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class CorrelatorInterceptor implements HttpInterceptor {
  public id: Guid = Guid.create();

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      if (!req.url.includes('cloudfunctions')) {
        req = req.clone({ headers: req.headers.set('X-Correlation-Id', this.id.toString())});
        return next.handle(req);
      } else {
        return next.handle(req);
      }
  }
}
