import { OKTA_CONFIG } from "@okta/okta-angular";
import { Router } from "@angular/router";
import { AppSettingsService } from "./app-settings.service";
import { FactoryProvider } from "@angular/core";
import OktaAuth, { OktaAuthOptions } from "@okta/okta-auth-js";

export function onAuthRequired(_oktaAuth: OktaAuth, router: Router) {
  router.navigate(['/login']);
}

export function oktaConfigFactory(appSettingsService: AppSettingsService) {
  const config = appSettingsService.getSettings();
  const oktaAuth = new OktaAuth(config.oidc);
  return {
    oktaAuth,
    onAuthRequired
  };
}

export const OktaConfigProvider: FactoryProvider = {
  provide: OKTA_CONFIG,
  useFactory: oktaConfigFactory,
  deps: [AppSettingsService],
};
