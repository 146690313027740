import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../dialogs/logout-dialog/logout-dialog.component';
import { Router, NavigationEnd } from '@angular/router';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isAuthenticated: boolean;
  public showNav = false;
  public enableAnimations = false;
  public route: string;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public oktaAuthState: OktaAuthStateService
  ) {}

  async ngOnInit() {
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();

    this.oktaAuthState.authState$.subscribe(
      authState  => this.isAuthenticated = authState.isAuthenticated
    );

    this.router.events.subscribe( e => {
      if (e instanceof NavigationEnd) {
        this.route = e.urlAfterRedirects;

        if (this.showNav) {
          this.toggleSideNav();
        }
      }
    });
  }

  public toggleSideNav() {
    document.querySelector('.sidebar').classList.add('active');
    this.showNav = !this.showNav;
  }

  public logout() {
    this.matDialog.open(LogoutDialogComponent, {
      panelClass: 'itv-dialog',
      height: '250px',
      width: '518px',
    });
  }

}
