<div class="unsuccessful">
  <img src="../../../../../../assets/icons/DeviceRequestUnsuccessful.svg" alt="request unsuccessful">
  <div class="unsuccessful-text">
    <h3 class="mat-headline unsuccessful-title">
      We are really sorry about this
    </h3>
    <p>
      Whilst we look into what happened you might want to contact TSD to check if they did receive your request.
    </p>
    <p>
      You can call them on:<br>
      <span class="bold">11555</span> (internal) or <span class="bold">+44 (0) 800 0232246</span> (external)
    </p>
    <p>
      Alternatively send them an email:<br>
      <span class="bold">TechnologyServiceDesk@itv.com</span>
    </p>
    <p *ngIf="showTechZone">
      or visit:<br>
      <span class="bold">https://mytechzone.itv.com/</span>
    </p>
  </div>
</div>

<div class="continue justify-content-center">
  <button [routerLink]="['/']" mat-flat-button color="transparent">Return to Embark homepage</button>
  </div>
