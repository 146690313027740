import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldValidationComponent } from './form-field-validation/form-field-validation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountSelectorComponent } from './account-selector/account-selector.component';
import { AppMaterialModule } from '../../app-material.module';
import { CardComponent, CardEntryComponent, CardHelpTextComponent, CardToggleComponent } from './ui-components/card/card.component';
import { UserSearchComponent } from './forms/user-search/user-search.component';
import { LoadingModalComponent } from './modals/loading-modal/loading-modal.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { WizardComponent } from './ui-components/wizard/wizard.component';
import { NoticeComponent, NoticeTitleComponent, NoticeMessageComponent } from './ui-components/notice/notice.component';
import { InformationModalComponent } from './modals/information-modal/information-modal.component';
import { WarningBannerComponent } from './warning-banner/warning-banner.component';
import { ErrorComponent } from './ui-components/error/error.component';
import { EllipsisPipe } from './pipes/ellipses.pipe';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AccountSelectorComponent,
    CardComponent,
    CardEntryComponent,
    CardToggleComponent,
    CardHelpTextComponent,
    NoticeComponent,
    NoticeTitleComponent,
    NoticeMessageComponent,
    FormFieldValidationComponent,
    UserSearchComponent,
    LoadingModalComponent,
    ErrorModalComponent,
    WizardComponent,
    InformationModalComponent,
    WarningBannerComponent,
    ErrorComponent,
    EllipsisPipe
  ],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    AccountSelectorComponent,
    FormFieldValidationComponent,
    CardComponent,
    CardEntryComponent,
    CardHelpTextComponent,
    CardToggleComponent,
    NoticeComponent,
    NoticeTitleComponent,
    NoticeMessageComponent,
    UserSearchComponent,
    LoadingModalComponent,
    ErrorModalComponent,
    WarningBannerComponent,
    WizardComponent,
    ErrorComponent,
    EllipsisPipe
  ]
})
export class SharedModule {
  /* istanbul ignore next */
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
