import { Injectable, isDevMode } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { environment } from 'src/environments/environment';
import { ErrorRequest, ErrorType, Severity } from '../services/error';

@Injectable()
export class ErrorLoggerInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === environment.stackdriverUrl) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let severity: Severity = Severity.WARNING;
        // if we're getting a 404 then service is not accesible and we need to be warned about it
        if (error.status === 404 || error.status === 503) {
          severity = Severity.CRITICAL;
        }

        const errorPackage = {
          type: ErrorType.HTTP,
          url: error.url,
          message: error.message,
          error: error?.error,
          referrer: window.location.href,
          severity,
          status: error.status,
        };

        if (!isDevMode()) {
          this.errorService.logStackdriver(errorPackage).pipe(first()).subscribe();
        }

        return throwError(error);
      })
    );
  }
}
