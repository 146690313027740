// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  gcpEnvironment: 'dev',
  production: true,
  // OKTA config - dev
  oidc: {
    clientId: '0oa9szcqzjKcYfbNg0x6',
    issuer: 'https://itvdev.oktapreview.com/oauth2/default',
    redirectUri: 'https://itv-embark-dev.appspot.com/implicit/callback',
    scopes: ['embark', 'openid', 'profile', 'email'],
    testing: {
      disableHttpsCheck: false
    }
  },
  stackdriverUrl: 'https://europe-west2-itv-embark-dev.cloudfunctions.net/error-logging',
  metadataSvcUrl: 'https://itv-gt-ent-metadata-dev.appspot.com/api/v1',
  identitySvcUrl: 'https://itv-gt-identity-service-dev.appspot.com/api/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
