<div class="warning-banner" [ngClass]="{ 'show': show }">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-8">
        <div class="content"><mat-icon class="warn">warning</mat-icon><span class="text">Hi there, before proceeding, please make sure all mandatory fields are complete</span>
          <mat-icon (click)="hideBanner()" class="close-btn">close</mat-icon></div>
      </div>
    </div>
  </div>
</div>
