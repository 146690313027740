import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, private router: Router) { }

  async canActivate(): Promise<boolean> {

    const isAuthenticated: boolean = await this.oktaAuth.isAuthenticated();

    if (isAuthenticated) {
      this.router.navigate(['requests']);
      return false;
    }

    return true;
  }

  async canActivateChild(): Promise<boolean> {

    const isAuthenticated: boolean = await this.oktaAuth.isAuthenticated();

    if (!isAuthenticated) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
