<nav *ngIf="route !== '/login'">
  <div class="container">
    <div class="row">
      <div class="col-1 d-block d-md-none">
        <button (click)="toggleSideNav()" class="navbar-toggler px-0 d-block d-md-none" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <mat-icon>menu</mat-icon>
        </button>
      </div>

      <div class="col-1 offset-4 offset-md-0 col-md-1 full-height">
        <a class="navbar-brand" routerLink="/">
          <img src="../assets/icons/itv-logo.png" alt="ITV" />
        </a>
      </div>

      <div class="col-md-7 full-height">
        <ul class="pl-0 d-none d-md-block">
          <li class="nav-item" *ngIf="isAuthenticated">
            <a mat-button routerLink="/requests" routerLinkActive="router-link-active">Requests</a>
          </li>
        </ul>
      </div>

      <div class="d-none d-md-block col-2 col-md-4 full-height">
        <div class="navbar-links">
          <div class="logout">
            <a mat-button role="link" (click)="logout()">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<app-warning-banner></app-warning-banner>
<div class="container no-height">
  <div class="row">
    <div class="col-md-10 offset-md-2">
    </div>
  </div>
</div>

<ng-container>
  <div class="d-block d-md-none">
    <div [class.show]="showNav" (click)="toggleSideNav()" class="overlay"></div>
    <nav [class.opened]="showNav" class="sidebar">
      <div class="holder">
        <div class="sidebar-close">
          <mat-icon (click)="toggleSideNav()">close</mat-icon>
        </div>
        <ul>
          <li *ngIf="isAuthenticated">
            <a mat-button routerLink="/requests" routerLinkActive="router-link-active">Requests</a>
          </li>
          <li *ngIf="isAuthenticated">
            <a mat-button (click)="logout()">Logout</a>
          </li>
        </ul>

        <a class="sidebar-brand" routerLink="/">
          <img src="../assets/icons/itv-logo.png" alt="ITV" />
        </a>
      </div>
    </nav>
  </div>
</ng-container>
