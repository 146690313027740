import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-parent',
  templateUrl: './error-parent.component.html',
  styleUrls: ['./error-parent.component.scss']
})
export class ErrorParentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
