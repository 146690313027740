import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Configuration } from "../models/configuration";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  settings: Observable<Readonly<Configuration>>;

  private settingsSource: BehaviorSubject<Configuration> = new BehaviorSubject<Configuration>({
    gcpEnvironment: undefined,
    production: undefined,
    oidc: {
      clientId: undefined,
      issuer: undefined,
      redirectUri: undefined,
      postLogoutRedirectUri: undefined,
      scopes: undefined
    },
    stackdriverUrl: undefined,
    metadataSvcUrl: undefined,
    identitySvcUrl: undefined
  });

  constructor() {
    this.settings = this.settingsSource.asObservable();
  }

  setSettings(settings: Partial<Configuration>) {
    environment.gcpEnvironment = settings.gcpEnvironment;
    environment.production = settings.production;
    environment.oidc = JSON.parse(JSON.stringify(settings.oidc));
    environment.stackdriverUrl = settings.stackdriverUrl;
    environment.metadataSvcUrl = settings.metadataSvcUrl;
    environment.identitySvcUrl = settings.identitySvcUrl;
    const updatedConfig = { ...this.settingsSource.value, ...settings };
    this.settingsSource.next(updatedConfig);
  }

  getSettings(): Readonly<Configuration> {
    return this.settingsSource.value;
  }
}
