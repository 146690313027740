import { ErrorHandler, Injectable, isDevMode} from '@angular/core';
import { ErrorRequest, ErrorType, Severity } from 'src/app/services/error';
import { ErrorService } from 'src/app/services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorService: ErrorService
  ) { }

  handleError(error) {
    let errorPackage: ErrorRequest;

    if (!isDevMode()) {
      errorPackage = {
        type: ErrorType.UX,
        message: error,
        referrer: window.location.href,
        severity: Severity.ERROR
      };

      return this.errorService.logStackdriver(errorPackage).subscribe(() => {
        throw error;
      });
    } else {
      throw error;
    }
  }

}
