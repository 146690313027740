import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient
} from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable()
export class IdentityMetaAuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(OKTA_AUTH) private oktaService: OktaAuth
  ) { }

  private setBearerToken(req, token) {
    return req.clone({ headers: req.headers.set('authorization', `Bearer ${token}`) });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('cloudfunctions')) {
      return next.handle(req);
    }

    // if request is to the identity service, metadata service then add an okta oauth token
    if (
      req.url.includes(environment.identitySvcUrl)
      || req.url.includes(environment.metadataSvcUrl)
      || req.url.includes('localhost')
    ) {
      const token = this.oktaService.getAccessToken();
      return next.handle(this.setBearerToken(req, token));
    }  else {
      return next.handle(req);
    }
  }
}
