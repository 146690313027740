import { Injectable, isDevMode } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorRequest, ErrorType, Severity } from './error';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private http: HttpClient
  ) { }

  logStackdriver(error: ErrorRequest) {
    if (isDevMode()) {
      return of();
    }
    const httpOptions = {
      headers: new HttpHeaders({})
    };

    return this.http.post(environment.stackdriverUrl, error, httpOptions)
      .pipe(catchError(res => res));
  }

  getClientStack(error: Error): string {
    return error.message;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }

  generateErrorRequest(
    errorType: ErrorType,
    severity: Severity = Severity.WARNING,
    referrer: string = window.location.href,
    message: string = 'error warning',
    error: string[] = [],
    status: number = null
  ): ErrorRequest {
    const errorReq: ErrorRequest = {
      type: errorType,
      severity,
      referrer,
      message,
      error,
      status
    };

    return errorReq;
  }
}
