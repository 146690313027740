import { Component, ChangeDetectorRef, OnInit, OnDestroy, Inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { VersionCheckService } from "./services/version-check.service";
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public path: string;
  title = 'Embark';
  isAuthenticated: boolean;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private readonly versionCheckService: VersionCheckService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)'); // 'sm'
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation - mobileQuery.addEventListener doesn't work on Safari!
    this.mobileQuery.addListener(this._mobileQueryListener);

    this._matIconRegistry.addSvgIcon(
      'ship-wheel',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/ship-wheel.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'fxf-key',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/key.svg')
    );

    this.oktaAuthStateService.authState$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(authState => this.isAuthenticated = authState.isAuthenticated);

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.path = e.url;
      }
      window.scrollTo(0, 0);
    });
  }

  /**
   * Checks if Okta acesss & ID token is set also,
   * an additional check for user calls Okta to check if the current session is valid
   * if toekns or valid is not availble, revokes token and redirects user to the login screen
   * */
  async ngOnInit() {
    this.versionCheckService.initVersionCheck();
    let user: UserClaims;

    try{
      this.isAuthenticated = await this.oktaAuth.isAuthenticated();

      if(this.isAuthenticated) {
        user = await this.oktaAuth.getUser();
      }
    } catch(e) {
      this.oktaAuth.revokeAccessToken();
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy() { }
}
