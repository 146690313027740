export enum Severity {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  ALERT = 'ALERT',
  EMERGENCY = 'EMERGENCY'
}

export enum ErrorType {
  UX = 'UX',
  HTTP = 'HTTP',
  GRAPHQL = 'GraphQL',
  SERVICENOW = 'ServiceNow'
}

export interface ErrorRequest {
  type: ErrorType;
  severity: Severity;
  referrer: string;
  message: string;
  error?: Array<string>;
  status?: number;
}
