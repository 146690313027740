import { Injectable, Injector } from "@angular/core";
import { LOCATION_INITIALIZED } from "@angular/common";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { AppSettingsService } from "./app-settings.service";
import { Configuration } from "../models/configuration";

export function preInitServiceFactory(
  initService: PreInitService,
  injector: Injector,
) {
  return () =>
    new Promise(resolve =>
      injector
        .get(LOCATION_INITIALIZED, Promise.resolve(undefined))
        .then(() => resolve(initService.onInit())),
    );
}

@Injectable()
export class PreInitService {

  private readonly configPath: string = '/assets/config/configuration.json';

  constructor(
    private httpBackend: HttpBackend,
    private appSettingsService: AppSettingsService,
  ) {}

  onInit(): Promise<boolean> {
    const http = new HttpClient(this.httpBackend);

    return http
      .get<Configuration>(this.configPath)
      .toPromise()
      .then(config => this.appSettingsService.setSettings(config))
      .then(() => console.log('app-settings.json loaded'))
      .then(_ => true)
      .catch(error => {
        console.error('Error loading application configuration', error);
        return Promise.resolve(false);
      });
  }

}
