import { Component, Inject, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit {
  public isAuthenticated: boolean;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    console.log(this.oktaAuth.isAuthenticated());
  }

  ngOnInit() {
  }

  public logout() {
    this.oktaAuth.signOut();
  }
}
