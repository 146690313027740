import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentVersion = '{{POST_BUILD_ENTERS_VERSION_HERE}}';

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url = '../assets/version.json', frequency = 30 * 60 * 1000) {
    if (this.currentVersion === '{{POST_BUILD_ENTERS_VERSION_HERE}}') {
      this.checkVersion(url);
    }
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {
          const version = response.version;
          const versionChanged = this.hasVersionChanged(this.currentVersion, version);

          // If new version, reload
          if (versionChanged) {
            this.reloadClient();
          }
          // store the new hash, so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentVersion = version;
        },
        (err) => {
          console.error('Could not get version', err);
        }
      );
  }

  private reloadClient() {
    document.location.reload();
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentVersion
   * @param newVersion
   * @returns {boolean}
   */
  private hasVersionChanged(currentVersion, newVersion) {
    if (!currentVersion || currentVersion === '{{POST_BUILD_ENTERS_VERSION_HERE}}') {
      return false;
    }

    return currentVersion !== newVersion;
  }
}
