<section>
  <h3 mat-dialog-title>Are you sure want to log out?</h3>

  <mat-dialog-content>
    Logging out of the ITV portal will log you out of all ITV systems, including your email and MyITV.
  </mat-dialog-content>
  <mat-dialog-actions class="action-bottom" align="center">
    <button mat-flat-button mat-dialog-close color="transparent">Cancel</button>
    <button mat-flat-button (click)="logout()" color="primary" class="mat-button-primary">Logout</button>
  </mat-dialog-actions>
</section>
