import { Injectable, EventEmitter } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public warningBanner = new EventEmitter();

  private config: MatSnackBarConfig = {
    duration: 3000,
    panelClass: 'error',
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  constructor(private snackBar: MatSnackBar) { }

  public showWarningBanner() {
    this.warningBanner.emit(true);
  }

  public hideWarningBanner() {
    this.warningBanner.emit(false);
  }

  showSuccessToast(message: string): void {
    this.config.panelClass = 'success';
    this.snackBar.open(message, null, this.config);
  }

  showErrorToast(message: string): void {
    this.snackBar.open(message, null, this.config);
  }
}
